<script setup>
import { Head, Link } from '@inertiajs/vue3';
import Logo from '../Components/Logo.vue';
import SecondaryButtonLink from '@/Components/SecondaryButtonLink.vue';
import GitlabIcon from '@/Components/GitlabIcon.vue';
import PrimaryButtonLink from '@/Components/PrimaryButtonLink.vue';
import { usePolicies } from '@/Composables/Permissions.js';

const { can } = usePolicies();

defineProps({
    canLogin: Boolean,
    canTrackTime: Boolean,
    canSeeInfrastructure: Boolean,
    canCreateInfrastructure: Boolean,
});
</script>

<template>
    <Head title="Welcome" />

    <div class="relative sm:flex sm:justify-center sm:items-center min-h-screen bg-dots-darker bg-center bg-gray-100 dark:bg-dots-lighter dark:bg-gray-900 selection:bg-red-500 selection:text-white">
        <div v-if="canLogin" class="sm:fixed sm:top-0 sm:right-0 p-6 text-right">
            <Link v-if="$page.props.auth.user" :href="route('dashboard')" class="font-semibold text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white focus:outline focus:outline-2 focus:rounded-sm focus:outline-brand-primary">Dashboard</Link>

            <template v-else>
                <div class="flex flex-wrap justify-end gap-4 max-w-2xl">
                    <SecondaryButtonLink :asVueLink="false" :href="route('oauth.login')">
                        <GitlabIcon class="inline-block h-4 w-4 mr-2" /> Log in with GitLab
                    </SecondaryButtonLink>
                    <PrimaryButtonLink :href="route('login')">Log in</PrimaryButtonLink>
                </div>
            </template>
        </div>

        <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 v-if="can('seeEmployeeResources')" class="text-center text-4xl text-gray-800 dark:text-white font-bold sm:text-6xl md:text-8xl">Opstimus Prime</h1>
            <div class="flex justify-center pt-8">
                <Logo />
            </div>
            <h1 v-if="! can('seeEmployeeResources')" class="text-center text-4xl text-gray-800 dark:text-white font-bold">LuminFire Dashboard</h1>
        </div>
    </div>
</template>
